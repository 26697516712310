jQuery(document).ready(function($) {
    // Check if there are more than one carousel
    if ($('.query-container').length > 0) {
        $('.query-container').each(function() {
            const $this = $(this);
            $this.slick({
                infinite: true,
                speed: 300,
                slidesToShow: 3,
                slidesToScroll: 3,
                responsive: [{
                        breakpoint: 985,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            }).on('setPosition', function(event, slick) {
                slick.$slides.css('height', slick.$slideTrack.height() + 'px');
            });

            $(window).on( "resize", function() {
                $this[0].slick.refresh();
            });
        });
    }
});
