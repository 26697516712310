document.addEventListener(
    "DOMContentLoaded",
    function () {
        import("./core/details").then((module) => {
            module.init();
        });
        import("./core/plus").then((module) => {
            module.init();
        });
    }
);
